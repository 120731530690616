@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,300&display=swap");
body {
  background-color: #2b3531;
  color: #f4f0e1;
  font-family: "Poppins";
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.navbar {
  flex: 0 1 50px;
}
.content {
  flex: 1 1 auto;
  width: 100%;
  text-align: center;
}

.grecaptcha-badge {
  display: none !important;
}

