.home-container {
  /* display: grid;
  grid-template-rows: 40% 60%; */

  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 20px 5vw;
  /* height: 90vh; */
  position: relative;
}
.home-row1-heading {
  font-weight: 500;
  font-size: 30px;
  line-height: 106px;
  color: #f4f0e1;
}
.home-row3 img {
  position: absolute;
}
.home-img1 {
  top: 0;
  right: 0;
}
.home-img2 {
  top: 20;
  left: 0;
}
.home-row1 {
  text-align: center;
  margin: 5vh auto 10vh auto;
  text-align: center;
  justify-content: center;
}
.home-row1-heading h1 {
  text-align: center;
}
.home-row1-btn {
  background-color: #b04814;
  color: #f4f0e1;
  border: 1.5px solid #e2624c;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  width: 200px;
  padding: 10px 20px;
}
.home-row1-message {
  color: #dc4506;
  letter-spacing: 0.5px;
}
.home-row1-btn {
  cursor: pointer;
}
.home-row2 {
  display: flex;
  justify-content: space-around;
}
.home-row2-img {
  width: 150px;
  padding: 20px;
}

/* Large devices */
@media (min-width: 1008px) {
}

/* medium devices */
@media (min-width: 641px) and (max-width: 1007px) {
}

/* mobile devices */
@media (max-width: 640px) {
  .home-row1-heading h1 {
    font-size: 40px;
  }
  .home-row2 {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .home-row1 {
    margin: 10vh auto 10vh auto;
  }
}
