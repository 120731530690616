@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #2b3531;
  color: #f4f0e1;
  font-family: "Poppins";
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.navbar {
  flex: 0 1 50px;
}
.content {
  flex: 1 1 auto;
  width: 100%;
  text-align: center;
}

.grecaptcha-badge {
  display: none !important;
}


nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    letter-spacing: 1px;
    padding: 25px 20px;
   
}

/* Navbar logo */
.nav-logo{
    padding-right: 5%;
}
.nav-logo:hover{
    cursor: pointer;
}

/* Navbar center links */
.nav-links{
    display: flex;
}
.nav-links div{
    margin: 20px;
}
a{
    text-decoration: none;
    color: white;
}
a:hover{
    color: cornsilk;
}

/* button */
.nav-btn{
    margin: 10px 0;
    padding: 0px 12px;
    color: white;
    background-color: #2b3531;
    border: 2.02899px solid #B04814;
    border-radius: 4px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
}
.nav-btn:hover{
    cursor: pointer;
    background-color:#333b38 ;
}

/* Mobile devices */
@media(max-width: 640px){
    .nav-links{
        display: none;
    }
}
.home-container {
  /* display: grid;
  grid-template-rows: 40% 60%; */

  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 20px 5vw;
  /* height: 90vh; */
  position: relative;
}
.home-row1-heading {
  font-weight: 500;
  font-size: 30px;
  line-height: 106px;
  color: #f4f0e1;
}
.home-row3 img {
  position: absolute;
}
.home-img1 {
  top: 0;
  right: 0;
}
.home-img2 {
  top: 20;
  left: 0;
}
.home-row1 {
  text-align: center;
  margin: 5vh auto 10vh auto;
  text-align: center;
  justify-content: center;
}
.home-row1-heading h1 {
  text-align: center;
}
.home-row1-btn {
  background-color: #b04814;
  color: #f4f0e1;
  border: 1.5px solid #e2624c;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  width: 200px;
  padding: 10px 20px;
}
.home-row1-message {
  color: #dc4506;
  letter-spacing: 0.5px;
}
.home-row1-btn {
  cursor: pointer;
}
.home-row2 {
  display: flex;
  justify-content: space-around;
}
.home-row2-img {
  width: 150px;
  padding: 20px;
}

/* Large devices */
@media (min-width: 1008px) {
}

/* medium devices */
@media (min-width: 641px) and (max-width: 1007px) {
}

/* mobile devices */
@media (max-width: 640px) {
  .home-row1-heading h1 {
    font-size: 40px;
  }
  .home-row2 {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .home-row1 {
    margin: 10vh auto 10vh auto;
  }
}


.about-text-container {
    margin: 0 9vw 120px 9vw;
  }
  .about-text-heading {
    font-size: 35px;
    line-height: 37px;
    text-align: left;
  }
  .about-hr {
    height: 0px;
    border: 1px solid #8c2e13;
    margin: 0 0 40px 0;
    width: 320px;
  }
  .about-text-subtext {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    text-align: justify;
  }

  /* Leaves */

  /* Large devices */
  @media (min-width: 1008px) {
    .about-container {
      display: flex;
      /* grid-template-columns: 70% 30%; */
    }
    .about-text-container {
      align-content: center;
      justify-content: center;
      margin: auto 9vw;
      padding-right: 4vw;
    }
    .about-text-container{
        text-align: left;
    }
    .about-leaves img {
      width: 300px;
    }
    .about-leaves-item2 img {
      width: 200px;
    }
    .about-text-subtext p {
      padding: 20px 0;
    }
    /* .about-leaves {
          position: relative;
        }
      .about-leaves-item1-lg {
          position: absolute;
          bottom: 0;
          right: 0;
        } */
    .about-text-subtext {
      font-size: 14px;
      text-align: justify;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.01em;
    }
    .about-leaves-item1-sm,
    .about-leaves-item2-sm {
      display: none;
    }
  }
  /* medium devices */
  @media (min-width: 641px) and (max-width: 1007px) {
    .about-text-heading {
      font-size: 24.4846px;
      line-height: 37px;
    }
    .about-hr {
        width: 215px;
      }
    .about-text-subtext {
      font-size: 14px;
      text-align: justify;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.01em;
    }
    .about-leaves-item1-lg,
    .about-leaves-item2-lg {
      display: none;
    }
    .about-leaves {
      position: relative;
    }
    .about-leaves-item1-sm {
      position: absolute;
      bottom: 0;
      right: 0;
    }

  }

  /* mobile devices */
  @media (max-width: 640px) {
    .about-text-heading {
      font-size: 24.4846px;
      line-height: 37px;
    }
    .about-text-container {
      margin: 2vh 9vw 120px 9vw;
    }
    .about-hr {
        width: 215px;
      }
    .about-text-subtext {
      font-size: 14px;
      text-align: justify;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.01em;
    }
    .about-leaves-item1-lg,
    .about-leaves-item2-lg {
      display: none;
    }
    .about-leaves {
      position: relative;
    }
    .about-leaves-item1-sm {
      position: absolute;
      bottom: 0;
      right: 0;
    }

  }
.instructions-container{
    text-align: center;
    margin: 10vh 20vw;
    /* height: 100vh; */
}
.instruction-set, .instruction-text{
    text-align: left;
}
.instructions-heading-hr{
    height: 0px;
    border: 1px solid #8C2E13;
    width: 230px;
    margin-bottom: 20px;
}
.instructions-heading-text h1{
    margin-bottom: 0;
    letter-spacing: 0.5px;

}

/* Mobile devices */
@media(max-width: 640px){
    .instructions-container{
        margin: 10vh 10vw;
    }
}
.instruction-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    align-content: center;
    padding: 10px;
    

}
.instruction-text{
    padding-left: 20px;
}
.leaderboardcard-container{
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding: 5px 20px;
    background: #3F4442;
    border-radius: 5.95821px;
    align-items: center;
    text-align: center;
    align-content: center;
    margin: 10px 20vw;

}
.leaderboard-contestant{
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    text-align: center;
    align-content: center;
    
}
.contestant-icon img{
    width: 30px;
    border-radius: 50%;

    
}
/* Mobile devices */
@media(max-width: 640px){
    .leaderboardcard-container{
        display: grid;
        grid-template-columns: 10% 80% 10%;
        padding: 5px 20px;
        margin: 10px 10vw;
    
    }
  }
.leaderboard-searchfield-container{
    color: #3F4442;
}
.leaderboard-searchfield {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 7px 20px;
    width: 250px;
    line-height: 30px;
    margin: 30px auto 30px auto;
    color: white;
    background-color: #3F4442!important;
    border-radius: 5.95821px;

}

.leaderboard-container {
  text-align: center;
  align-content: center;
  align-items: center;
}

.leaderboard-set-heading {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: 10px 20vw;
  padding: 5px 20px;
}
.leaderboard-heading h1{
  margin-bottom: 0;
}
.leaderboard-hr{
    height: 0px;
    height: 0px;
    border: 1px solid #8C2E13;
    width: 220px;
    margin-bottom: 20px;
}
.leaderboard-set-heading div {
  background-color: #606865;
  margin: 0 auto;
  padding: 10px 2vw;
  border-radius: 5.95821px;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.contestent-name{
   text-align: left;
}
.leaderboard-btn-list{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-content: center;
    align-items: center;
    margin: 10px 20vw;
    justify-content: center;
}
.leaderboard-btn-list div{
    padding: 10px;
    margin: 5px;
}
/* Mobile devices */
@media(max-width: 640px){
  .leaderboard-set-heading {
    margin: 10px 10vw;
  }
  .leaderboard-set-heading div {
    background-color: #606865;
    margin: 0 auto;
    padding: 10px 2vw;
    border-radius: 5.95821px;
    font-size: 0.7rem;
    text-transform: uppercase;
  }
  .leaderboard-set-heading {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    margin: 10px 10vw;
    padding: 5px 20px;
  }
}
.leaderboard-btn {
  background: #3f4442;
  border-radius: 1.35099px;
  /* width: 50.66px;
height: 51.34px; */
  padding: 13.5099px 22.2913px;
  color: white;
  margin-top: 20px;
  border: none;
}
.leaderboard-btn:hover{
  cursor: pointer;
}
.footer-container {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer-1 {
  margin: 50px 20vw 9vw 20vw;
}
.footer-1-subtext{
    padding-bottom: 10px;
}
.footer-2-heading{
    font-size: 25px;
}
/* .footer-2-container{
    background-color:#F4F0E1 ;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    color: black;
    padding: 50px 0;
    align-items: center;
} */
.footer-2-container-sm{
    display: none;
}
.footer-2-container-lg{
    background-color:#F4F0E1 ;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    color: black;
    padding: 50px 0;
    align-items: center;
}
.footer-social-icon{
    margin: 1.5vw;
}
/* Large devices */
@media (min-width: 1008px) {
}

/* medium devices */
@media (min-width: 641px) and (max-width: 1007px) {
    .footer-social-icon{
        margin: 1vw;
    }
    .footer-2-lg-grid1, .footer-2-lg-grid3{
        width: 150px;
    }
}

/* mobile devices */
@media (max-width: 640px) {

    .footer-1{
        margin: 50px 10vw 50px 10vw;
    }
    .footer-1-subtext{
        text-align: justify;
        padding-bottom: 30px;
    }
    .footer-2-container-lg{
        display: none;
    }
  .footer-2-container-sm {
    background-color: #f4f0e1;
    width: 100%;
    display: flex;
    flex-direction:column;
    color: black;
    padding: 50px 0;
    align-items: center;
  }
  .footer-2-sm-grid1{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

  }
  .footer-2-sm-img{
    width: 150px;
    padding: 10px;
    margin: 2vw;
  }
  
  .footer-2-heading{
    font-size: 20px;
}
}

.enroll-btn {
  padding: 20px;
  background: #dbe8d9;
  border-radius: 5px;
}
.enroll-btn:hover{
    cursor: pointer;
    background: #e6f0e4;
}
.postregister-container{
 text-align: center;
}
.postregister-container{
  margin-top: 100px;
}
