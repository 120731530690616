.leaderboard-searchfield-container{
    color: #3F4442;
}
.leaderboard-searchfield {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 7px 20px;
    width: 250px;
    line-height: 30px;
    margin: 30px auto 30px auto;
    color: white;
    background-color: #3F4442!important;
    border-radius: 5.95821px;

}
