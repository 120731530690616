.leaderboard-btn {
  background: #3f4442;
  border-radius: 1.35099px;
  /* width: 50.66px;
height: 51.34px; */
  padding: 13.5099px 22.2913px;
  color: white;
  margin-top: 20px;
  border: none;
}
.leaderboard-btn:hover{
  cursor: pointer;
}