.footer-container {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer-1 {
  margin: 50px 20vw 9vw 20vw;
}
.footer-1-subtext{
    padding-bottom: 10px;
}
.footer-2-heading{
    font-size: 25px;
}
/* .footer-2-container{
    background-color:#F4F0E1 ;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    color: black;
    padding: 50px 0;
    align-items: center;
} */
.footer-2-container-sm{
    display: none;
}
.footer-2-container-lg{
    background-color:#F4F0E1 ;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    color: black;
    padding: 50px 0;
    align-items: center;
}
.footer-social-icon{
    margin: 1.5vw;
}
/* Large devices */
@media (min-width: 1008px) {
}

/* medium devices */
@media (min-width: 641px) and (max-width: 1007px) {
    .footer-social-icon{
        margin: 1vw;
    }
    .footer-2-lg-grid1, .footer-2-lg-grid3{
        width: 150px;
    }
}

/* mobile devices */
@media (max-width: 640px) {

    .footer-1{
        margin: 50px 10vw 50px 10vw;
    }
    .footer-1-subtext{
        text-align: justify;
        padding-bottom: 30px;
    }
    .footer-2-container-lg{
        display: none;
    }
  .footer-2-container-sm {
    background-color: #f4f0e1;
    width: 100%;
    display: flex;
    flex-direction:column;
    color: black;
    padding: 50px 0;
    align-items: center;
  }
  .footer-2-sm-grid1{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

  }
  .footer-2-sm-img{
    width: 150px;
    padding: 10px;
    margin: 2vw;
  }
  
  .footer-2-heading{
    font-size: 20px;
}
}
